export const updateTable = (setLanguage) => {
  // 달러가격과 연동하면 토큰가격 추가
  return [
    {
      [setLanguage.help1]: "Diamond",
      [setLanguage.help2]: "$ 160",
      [setLanguage.help28]: "196,000₩",
      // [setLanguage.help29]: "12%",
      [setLanguage.help4]: "500,000p",
    },
    {
      [setLanguage.help1]: "Black Diamond",
      [setLanguage.help2]: "$ 164",
      [setLanguage.help28]: "200,000₩",
      // [setLanguage.help29]: "14%",
      [setLanguage.help4]: "500,000p",
    },
    {
      [setLanguage.help1]: "Blue Diamond",
      [setLanguage.help2]: "$ 286",
      [setLanguage.help28]: "350,000₩",
      // [setLanguage.help29]: "13%",
      [setLanguage.help4]: "500,000p",
    },
    {
      [setLanguage.help1]: "Red Diamond",
      [setLanguage.help2]: "$ 408",
      [setLanguage.help28]: "500,000₩",
      // [setLanguage.help29]: "14%",
      [setLanguage.help4]: "500,000p",
    },
  ];
};

export const updateDetail = (
  nowGrade,

  setLanguage
) => {
  const level = nowGrade;

  return [
    {
      [setLanguage]: level,
    },
  ];
};
