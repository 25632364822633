// 테스트넷 안하면 지워야함
export const addNetwork = async (networks) => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: networks.ChainId }],
    });
  } catch (switchError) {
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: networks.ChainId,
              chainName: networks.ChainName,
              nativeCurrency: networks.nativeCurrency,
              rpcUrls: networks.rpcUrls,
              blockExplorerUrls: networks.blockExplorerUrls,
            },
          ],
        });
      } catch (addError) {
        console.log(addError);
      }
    } else {
      console.log(switchError);
    }
  }
};

export const addToken = async () => {
  const tokenAddress = process.env.VUE_APP_TOKEN;
  const tokenSymbol = "EDT";
  const tokenDecimals = 18;

  try {
    await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          image:
            "https://static.wixstatic.com/media/c899c0_c6460761ba6e4ef2920c08d752e4c2f4%7Emv2.png/v1/fill/w_32%2Ch_32%2Clg_1%2Cusm_0.66_1.00_0.01/c899c0_c6460761ba6e4ef2920c08d752e4c2f4%7Emv2.png",
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const accountCall = async () => {
  try {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    return accounts[0];
  } catch (error) {
    console.log(error);
    // this.showMsgBoxTwo(a);
  }
};
