<template>
  <div class="adminFirstTop mb-4">
    <div class="d-flex justify-content-between buttonRadius1">
      <div class="fontSizeL textColorMEF">TVL</div>
      <!-- 위치수정 -->

      <div class="colum-center">
        <b-spinner v-if="TVL === null"></b-spinner>
        <div v-else>
          <div class="fontSizeM bottomMarginm">
            {{ balance }} <strong>EDT</strong>
          </div>
          <div class="textRight textColor bottomMarginm fontSizeSmB">
            = $ {{ TVL }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tvl",
  props: {
    TVL: String,
    balance: String,
  },
};
</script>
