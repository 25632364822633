<template>
  <!-- 사이드바 시작 -->
  <b-sidebar
    id="sidebar-right"
    width="310px"
    no-header
    right
    shadow
    bg-variant="white"
  >
    <template #default="{ hide }">
      <div class="px-3 py-2">
        <div class="positionRelative d-flex mt-2 mb-3">
          <div class="fs-5 font171">
            {{ text1 }}
          </div>
          <div
            @click="
              hide(),
                (sideVisible = false),
                (lenguageUpDown = 0),
                (sideColor = 'sideBlack')
            "
          >
            <b-icon icon="x" scale="2" class="position-absolute end-0"></b-icon>
          </div>
        </div>

        <div
          @click="
            $emit('addTokenCall'),
              (sideColor = 'sideBlack'),
              (sideVisible = false),
              (lenguageUpDown = 0),
              hide()
          "
          class="sideBlack pointer py-4 d-flex align-items-center"
        >
          <img src="../../assets/img/edustar112.png" alt="" class="iconSize" />

          <button
            class="mx-2 fontWeight400 sideBlack d-flex align-items-center"
          >
            {{ text2 }}
          </button>
        </div>
        <div class="pb-4">
          <div
            :class="`d-flex justify-content-between align-items-center ${sideColor}`"
            v-b-toggle.collapse-a.collapse-b
            @click="(lenguageUpDown += 180), sideColorChange(sideVisible)"
          >
            <div class="d-flex align-items-center">
              <img
                src="../../assets/img/language_white_24dp.svg"
                alt=""
                class="iconSize"
                v-if="sideVisible == false"
              />
              <img
                src="../../assets/img/language_color_24dp.svg"
                alt=""
                class="iconSize"
                v-else
              />
              <button :class="`mx-2 fontWeight400 ${sideColor}`">
                {{ text3 }}
              </button>
            </div>
            <b-icon
              :rotate="lenguageUpDown"
              icon="caret-down-fill"
              :class="sideVisible ? 'sideGray' : 'sideBlack'"
            ></b-icon>
          </div>
          <b-collapse
            id="collapse-a"
            :aria-expanded="sideVisible ? 'true' : 'false'"
            v-model="sideVisible"
          >
            <div @click="$emit('languageChangEn')" class="pointer">
              <div class="marginLeft child fontWeight400">English</div>
            </div>
          </b-collapse>
          <b-collapse
            id="collapse-b"
            :aria-expanded="sideVisible ? 'true' : 'false'"
            v-model="sideVisible"
          >
            <div @click="$emit('languageChangKr')" class="pointer">
              <div
                text-variant="secondary"
                class="marginLeft child fontWeight400"
              >
                한국어
              </div>
            </div>
          </b-collapse>
        </div>
        <div
          class="sideBlack pointer pb-4 d-flex align-items-center"
          @click="
            $emit('sideToAdminCall'),
              (sideVisible = false),
              (lenguageUpDown = 0),
              (sideColor = 'sideBlack');
            hide();
          "
          v-if="areYou"
        >
          <img
            src="../../assets/img/admin_panel_settings_opsz24B.svg"
            class="iconSize"
          />

          <button class="mx-2 fontWeight400 sideBlack">
            {{ text6 }}
          </button>
        </div>
        <div
          class="sideHeight"
          @click="
            hide(),
              (sideColor = 'sideBlack'),
              (sideVisible = false),
              (lenguageUpDown = 0)
          "
          aria-controls="collapse-b"
        >
          .
        </div>
      </div>
    </template>
  </b-sidebar>
  <!-- 사이드바 끝 -->
  <!-- 로그아웃 끝 -->
</template>

<script>
export default {
  name: "Sidebar",
  emits: [
    "addTokenCall",
    "languageChangEn",
    "languageChangKr",
    "sideToAdminCall",
  ],
  props: {
    text1: String,
    text2: String,
    text3: String,
    text6: String,
    areYou: Boolean,
  },
  data() {
    return {
      lenguageUpDown: 0,
      sideColor: "sideBlack",
      sideVisible: false,
    };
  },
  methods: {
    sideColorChange(num) {
      switch (num) {
        case true:
          this.sideColor = "sideBlack";
          break;
        case false:
          this.sideColor = "sideGray";
          break;
      }
    },
  },
};
</script>
