<template>
  <div class="my-5">
    <div class="d-flex justify-content-between buttonRadius1">
      <span class="fs-6"
        ><strong>{{ text1 }}</strong></span
      >
      <BasicButton @click.native="notionSetting()" :text="text2" />
    </div>

    <b-row class="rowMargin mt-3">
      <b-col cols="12" class="px-0">
        <b-form-datepicker v-model="notificationDate"></b-form-datepicker>
      </b-col>

      <BasicInputText
        @updateParentInputText="notificationText = $event.target.value"
        :title="notificationText"
        :text="text3"
      />
      <BasicInputText
        @updateParentInputText="notificationLink = $event.target.value"
        :title="notificationLink"
        :text="text4"
      />
      <b-col cols="12" class="px-0 mt-3">
        <div class="d-flex justify-content-between">
          <div>{{ text5 }}</div>
          <div>
            <img
              class="pointer"
              @click="(showNotion = true), notionUpdate(seletedNotion)"
              src="../../assets/img/edit_black_18dp.svg"
              alt="update"
            />
            <img
              class="pointer"
              @click="notionDel(seletedNotion)"
              src="../../assets/img/delete_black_18dp.svg"
              alt="delete"
            />
          </div>
        </div>
        <hr />

        <b-form-radio-group
          id="radio-group-2"
          v-model="seletedNotion"
          plain
          stacked
        >
          <div v-if="this.notification.length <= 0">
            <div>{{ text6 }}</div>
          </div>
          <b-form-radio
            v-else
            v-for="(list, index) in notification"
            :value="notification[index].num"
            :key="index"
          >
            <div class="d-flex justify-content-between smText1">
              <div class="radioInLabel">
                {{ list.main }}
              </div>
              <div class="radioInLabel">
                {{ list.date.slice(5, 10) }}
              </div>
            </div>
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>
    <!-- 공지 모달 -->
    <b-modal v-model="showNotion" hide-footer hide-header centered>
      <div class="absoluteRight pointer" @click="showNotion = false">
        <b-icon icon="x" scale="1.5" variant="secondary"></b-icon>
      </div>
      <h4 class="centerPlz">
        {{ text7 }}
      </h4>
      <b-row class="rowMargin mt-3">
        <b-col cols="12" class="px-0">
          <b-form-datepicker
            v-model="notificationDateUpdate"
          ></b-form-datepicker>
        </b-col>
        <BasicInputText
          @updateParentInputText="notificationTextUpdate = $event.target.value"
          :title="notificationTextUpdate"
          :text="''"
        />
        <BasicInputText
          @updateParentInputText="notificationLinkUpdate = $event.target.value"
          :title="notificationLinkUpdate"
          :text="''"
        />
      </b-row>
      <div class="buttonRadius1 centerPlz mt-3">
        <b-button @click="notionUpdateServer()" class="me-1 buttonColor">{{
          text8
        }}</b-button>
      </div>
    </b-modal>
    <!-- 공지 모달 끝 -->
  </div>
</template>

<script>
import { api } from "../../control/axios";
import { check } from "../../control/admin";
import BasicButton from "../basicUse/BasicButton.vue";
import BasicInputText from "../basicUse/BasicInputText.vue";
export default {
  name: "NotionUp",
  components: {
    BasicButton,
    BasicInputText,
  },
  emits: [
    "notificationCopy",
    "numListCopy",
    "msgBox",
    "notificationUp",
    "notificationCut",
    "numListCut",
    "numListCut1",
    "carouselUp",
  ],
  props: {
    notification: Array,
    notificationNumList: Array,
    account: String,
    text1: String,
    text2: String,
    text3: String,
    text4: String,
    text5: String,
    text6: String,
    text7: String,
    text8: String,
    text9: String,
    text10: String,
    text11: String,
    text12: String,
  },
  data() {
    return {
      api,
      check,
      notificationText: null,
      notificationDate: null,
      notificationLink: null,
      notificationTextUpdate: null,
      notificationDateUpdate: null,
      notificationLinkUpdate: null,
      seletedNotion: null,
      showNotion: false,
    };
  },
  methods: {
    async axiosUpdate() {
      const checkAddress = await this.check();
      if (checkAddress) {
        try {
          this.api("/api/notificationUpdate", "post", {
            param: [this.notification, this.notificationNumList, this.account],
          }).then((result) => {
            this.$emit("notificationCopy", result.c);
            this.$emit("numListCopy", result.updateA);
            this.$emit("msgBox", this.text9);
          });
        } catch (error) {
          this.$emit("msgBox", this.text10);
        }
      } else {
        alert("admin only");
      }
    },

    async notionDel(index) {
      const checkAddress = await this.check();
      if (checkAddress) {
        const delte = this.seeIndex(index);
        this.$emit("notificationCut", delte);
        this.$emit("numListCut1", index);
        this.axiosUpdate();
        if (this.notification.length === 0) {
          this.$emit("carouselUp");
        }
      } else {
        alert("admin only");
      }
    },

    seeIndex(index) {
      for (var i = 0; i < this.notification.length; i++) {
        if (this.notification[i].num == index) {
          return i;
        }
      }
    },

    notionUpdate(index) {
      this.lookNum = this.seeIndex(index);
      const num = this.lookNum;
      this.notificationTextUpdate = this.notification[num].main;
      this.notificationDateUpdate = this.notification[num].date;
      this.notificationLinkUpdate = this.notification[num].link;
    },

    notionUpdateServer() {
      const num = this.lookNum;
      this.notification[num].main = this.notificationTextUpdate;
      this.notification[num].date = this.notificationDateUpdate;
      this.notification[num].link = this.notificationLinkUpdate;

      this.axiosUpdate();

      this.showNotion = false;
    },
    notionSetting() {
      if (this.notification.length < 10) {
        if (
          this.notificationText != null &&
          this.notificationDate != null &&
          this.notificationLink != null
        ) {
          const firstNum = this.notificationNumList[0];
          this.$emit("numListCut");
          const date = {
            main: this.notificationText,
            date: this.notificationDate,
            link: this.notificationLink,
            num: firstNum,
          };
          this.$emit("notificationUp", date);
          this.axiosUpdate();
          this.notificationText = null;
          this.notificationDate = null;
          this.notificationLink = null;
        } else {
          this.$emit("msgBox", this.text11);
        }
      } else {
        this.$emit("msgBox", this.text12);
      }
    },
  },
};
</script>
