import { accountCall } from "./metamask1";
import { api } from "./axios";

const realCheck1 = async (list) => {
  const result = await api("/api/realCheck", "post", {
    param: [list],
  });
  return result;
};

export const check = async () => {
  const real = await accountCall();
  const result = await realCheck1(real);
  return result;
};
