<template>
  <div class="text-center">
    <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
    <p id="cancel-label">Please wait transaction...</p>
    <b-button
      variant="outline-danger"
      size="sm"
      aria-describedby="cancel-label"
      @click="$emit('showWaitFalse')"
    >
      Cancel
    </b-button>
  </div>
</template>

<script>
export default {
  name: "Overlay",
  emits: ["showWaitFalse"],
};
</script>
