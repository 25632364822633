import { api } from "../control/axios";

export const axiosCall = async () => {
  try {
    const result = await api("/api/notificationDown", "post");
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const callBasic = async () => {
  try {
    const list = await api("/api/makeBasicList", "post");
    return list;
  } catch (error) {
    console.log(error);
  }
};
