<template>
  <div class="pointer d-flex justify-content-between">
    <!-- 화면 크기에 따른 MEF 로고 변화 -->
    <div class="d-flex align-items-center">
      <img src="../../assets/img/EDT_LOGO_XS.png" alt="" />
      <!-- 화면 크기에 따른 MEF 로고 변화 끝-->
      <span class="dustar">
        <strong>EDUSTAR </strong>
      </span>
    </div>
    <!-- 로그아웃 시작 -->
    <div>
      <b-button class="buttonAmpty buttonBorder1" size="sm" @click="logOut()">
        <img src="../../assets/img/logout_black_24dp.svg" alt="" />
      </b-button>
      <b-button
        v-b-toggle.sidebar-right
        class="buttonAmpty buttonBorder1 p-0"
        size="sm"
      >
        <img src="../../assets/img/menu_black_24dp.svg" alt=""
      /></b-button>
      <!-- 로그아웃 끝 -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Small",
  methods: {
    logOut() {
      sessionStorage.clear();
      window.open("https://edustar.site/", "_self");
    },
  },
};
</script>
