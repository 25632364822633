<template>
  <b-button class="smButton8 buttonColor1">{{ text }}</b-button>
</template>

<script>
export default {
  name: "BasicButtonLongG",
  props: ["text"],
};
</script>
