import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_BASEURL;

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8;";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export const api = async (url, method, data) => {
  // 서버 연결
  return (
    await axios({
      method: method,
      url: url,
      data: data,
    }).catch((e) => {
      console.log(e);
    })
  ).data;
};
// https 인증 전에 깃 클론 하고 필요한 셋팅 다 설치
