import { getTokenContract } from "./contractData";
import { fromWei, BN } from "./fromWei";

//토큰 양 확인
export const myBalance = async (address) => {
  const contract = await getTokenContract();
  let balance = await contract.methods.balanceOf(address).call();
  balance = fromWei(balance);

  return balance;
};

//토큰 심볼 확인
// export const mySymbol = async () => {
//   console.log("mySymbol");
//   const contract = await getTokenContract();
//   let symbol = await contract.methods.symbol().call();

//   return symbol;
// };

//토큰 사용 가능 확인
/// 컨트랙트 하나로 묶어서 이 함수를 다 사용 가능하게 approve도 마찬가지
export const allowance = async (spender, owner) => {
  //토큰 컨트랙트
  const contract = await getTokenContract();

  let allow = await contract.methods.allowance(owner, spender).call();
  allow = BN(allow).toString();
  return allow;
};
