<template>
  <div class="pointer" shift-v="-1">
    <b-icon icon="chevron-left" class="iconColor1" font-scale="1.75"></b-icon>
    <span class="fs-5 iconColor1"
      ><strong>{{ text }}</strong></span
    >
  </div>
</template>
<script>
export default {
  name: "MobileGoHomeIcon",
  props: ["text"],
};
</script>
