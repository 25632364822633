<template>
  <b-col cols="12" class="px-0 mt-1">
    <input
      type="text"
      class="sizee rowBorder borderRadius px-2 mb-0"
      :value="title"
      @input="keyCatch(), $emit('updateParentInputText', $event)"
      :placeholder="text"
  /></b-col>
</template>

<script>
export default {
  name: "BasicInputText",
  emits: ["updateParentInputText"],
  props: {
    title: String,
    text: String,
  },
  methods: {
    keyCatch() {
      const regex = /[<>&]/g;
      event.target.value = event.target.value.replace(regex, "");
    },
  },
};
</script>
