import { getMembershipContract } from "./contractData";
import { fromWei, BN } from "./fromWei";
import greenDia from "../assets/data/greenDia.json";

export const getUserInfo = async (address) => {
  const contract = await getMembershipContract();
  const info = await contract.methods.userInfos(address).call();
  return info;
};

export const getOwner = async () => {
  const contract = await getMembershipContract();
  let owner = await contract.methods.owner().call();
  return owner.toLowerCase();
  // this.contractOwner =
};

// 전체 토큰가격 한번에 불러오기
export const getDepositData = async () => {
  const contract = await getMembershipContract();
  const depositData = await contract.methods.seeMembershipTokenAmount().call();

  let membershipCount = depositData.length;
  let depositFee50 = [];
  for (let index = 0; index < membershipCount; index++) {
    depositFee50[index] = depositData[index];
  }

  // const depositFee50 = [
  //   depositData[0], // 멤버십 1번 달러가격
  //   depositData[1], // 멤버십 1번 토큰 가격
  //   depositData[2], // 멤버십 2번 토큰 가격
  //   depositData[3], // 멤버십 3번 토큰 가격
  //   depositData[4], // 멤버십 4번 토큰 가격
  // ];

  // 나중에 체인에 있는거 불러와서 할거면 / 10000 해야함. ($1 = 10000 으로 계산해둠) ㅇ
  // 4000 달러 기준으로 해서 1달러 구하기 위해(현재 4000달러 는 4000 * 10000의 값으로 체인에 기록되어 있음(소수점 네번째자리까지 달러 가격을 계산하기 위해))
  let a = BN(depositFee50[1]);
  // console.log("hi");
  // let b = BN(4000);
  let b = BN(Number(depositFee50[0]) / 10000);
  // console.log(b);
  const USD = fromWei(a.div(b));
  return [depositFee50, USD, membershipCount];
};

//컨트랙트를 이용한 등급 별 주소 가져오기
export const seeAddressToGrade = async (membershipCount) => {
  const contract = await getMembershipContract();

  let list = [];
  const no = [{ address: "None" }];
  let resultGrade = [];
  // console.log(greenDia);
  resultGrade[999] = greenDia; // 그린다이아

  // 15만 유저 대비
  for (let index = 1; index < membershipCount; index++) {
    list[index] = [];
    // 첫 포문을 넘기기 위해서
    let result = new Array(1000);
    // 리턴받은 길이가 1000개면 다음 리스트로 넘어가서 또 데이터 받아옴(저장할 때 최대 1000개씩 멥핑함)
    for (let num = 0; result.length == 1000; num++) {
      result = await contract.methods.seeGradeToAddress(index, num).call();
      result = toObject(result);
      list[index] = list[index].concat(result);
    }
    resultGrade[index] = list[index].length === 0 ? no : list[index];
  }
  return resultGrade;
};

function toObject(address) {
  let result = [];
  for (let index = 0; index < address.length; index++) {
    result[index] = { address: address[index] };
  }
  return result;
}

export const getTVL = async () => {
  const contract = await getMembershipContract();
  let balance = await contract.methods.TVL().call();

  balance = fromWei(balance);
  return balance;
};
