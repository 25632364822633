export const colorChangeFunction = (check) => {
  switch (check) {
    case 0:
      return {
        stake: "iconColor",
        admin: "iconColor1",
        realStake: "iconColor1",
        svgA: require("../assets/img/group_add_opsz24.svg"),
        svgD: require("../assets/img/admin_panel_settings_opsz24B.svg"),
        svgC: require("../assets/img/person_search_opsz24B.svg"),
      };

    case 2:
      return {
        stake: "iconColor1",
        admin: "iconColor",
        realStake: "iconColor1",
        svgA: require("../assets/img/group_add_opsz24B.svg"),
        svgD: require("../assets/img/admin_panel_settings_opsz24.svg"),
        svgC: require("../assets/img/person_search_opsz24B.svg"),
      };

    case 3:
      return {
        stake: "iconColor1",
        admin: "iconColor1",
        realStake: "iconColor",
        svgA: require("../assets/img/group_add_opsz24B.svg"),
        svgD: require("../assets/img/admin_panel_settings_opsz24B.svg"),
        svgC: require("../assets/img/person_search_opsz24.svg"),
      };
  }
};
