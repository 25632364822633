export const tvlChange = (tvl) => {
  const tvlLangth = tvl.length;
  let stringTvl = String(tvl);
  if (tvlLangth > 8) {
    stringTvl = stringTvl.slice(0, -6) + "M";
    return stringTvl;
  } else if (tvlLangth > 6) {
    stringTvl = stringTvl.slice(0, -6) + "." + stringTvl.slice(-6, -4) + "M";
    return stringTvl;
  } else if (tvlLangth > 3) {
    stringTvl = stringTvl.slice(0, -3) + "." + stringTvl.slice(-3, -1) + "K";
    return stringTvl;
  } else {
    return stringTvl;
  }
};
