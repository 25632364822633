<template>
  <div class="d-flex justify-content-center">
    <b-carousel id="carousel-fade" no-animation indicators>
      <b-carousel-slide class="imagePadding" :style="data"></b-carousel-slide>
      <!-- :img-src="require(`@/assets/img/${gradeToImage}`)" -->

      <b-carousel-slide
        v-for="(list, n) in bannerList"
        :key="n"
        class="imagePadding"
        :style="data1(list)"
      ></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "Front",
  props: {
    gradeToImage: String,
    bannerList: Array,
  },
  // 키로 업데이트로 대체함
  // watch: {
  //   // 내 멤버쉽 등급에 따라, 원하는 등급이 되고 싶을 때, 스테이킹 해야 할 가격 계산
  //   async bannerList(val) {
  //     if (!val) return;
  //     console.log("카드 다시 랜더링", this.bannerList);
  //     console.log(this.gradeToImage);
  //     // this.$forceUpdate();
  //   },
  // },
  created() {
    this.call = process.env.VUE_APP_BASEURL;
  },
  data() {
    return {
      data: {
        backgroundImage: `url(${require(`@/assets/img/${this.gradeToImage}`)})`,
      },
    };
  },
  methods: {
    data1(list) {
      return {
        backgroundImage: `url(${this.call}/api/download/${list})`,
      };
    },
  },
};
</script>
