// 컨트랙트에 저장된 등급 확인
export const switchGrade = (grade) => {
  switch (grade) {
    case "0":
      return "None";
    case "1":
      return "Diamond";
    case "2":
      return "Black Diamond";
    case "3":
      return "Blue Diamond";
    case "4":
      return "Red Diamond";
    case "999":
      return "Green Diamond";
  }
};

const gradeToNum = (grade) => {
  let gradeNum = Number(grade);
  // if (gradeNum === 999) {
  //   gradeNum = 0;
  // }
  return gradeNum;
};

export const gradeToText = (grade) => {
  const num = gradeToNum(grade);
  const gradeList = [
    "None",
    "Diamond",
    "Black Diamond",
    "Blue Diamond",
    "Red Diamond",
    "Green Diamond",
  ];
  const imageList = [
    "card0.png",
    "card1.png",
    "card2.png",
    "card3.png",
    "card4.png",
    "card5.png",
  ];
  let nowGrade;
  let gradeToImage;
  if (grade == 999) {
    nowGrade = gradeList[5];
    gradeToImage = imageList[5];
  } else {
    nowGrade = gradeList[num];
    gradeToImage = imageList[num];
  }
  return [nowGrade, gradeToImage];
};
