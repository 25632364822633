<template>
  <div>
    <div class="buttonSide">
      <div
        @click="$emit('goMain')"
        class="mt-2 mb-2 py-2 pe-1 paddingLeftMypage buttonSplit pointer"
      >
        <img :src="colorChange.svgC" />

        <h5 :class="`fontBold1 ${colorChange.realStake}`">
          {{ text1 }}
        </h5>
      </div>
      <div
        @click="$emit('goStake')"
        class="buttonSplit mt-3 mb-2 py-2 ps-1 pointer"
      >
        <img :src="colorChange.svgA" />

        <h5 :class="`fontBold1 ${colorChange.stake}`">
          {{ text2 }}
        </h5>
      </div>

      <div
        @click="$emit('sideToAdminCall')"
        class="mt-3 mb-2 py-2 pe-1 d-flex pointer"
        v-if="areYou"
      >
        <b-img class="pe-1" :src="colorChange.svgD" />
        <h5 :class="`fontBold1 ${colorChange.admin}`">
          {{ text4 }}
        </h5>
      </div>
    </div>
    <div class="absoluteBottton">
      <div @click="$emit('addTokenCall')">
        <b-button size="sm" class="buttonBorder mx-1">
          <b-icon icon="wallet2" class="marginRight" font-scale="1.5"></b-icon>
          EDT
        </b-button>
        <p class="iconColor1 mt-1 mb-0 addEDTP">
          {{ text5 }}
        </p>
        <p class="iconColor1 addEDTP mt-0">
          {{ text6 }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftSide",
  emits: ["goMain", "goStake", "sideToAdminCall", "addTokenCall"],
  props: {
    text1: String,
    text2: String,
    text4: String,
    text5: String,
    text6: String,
    areYou: Boolean,
    colorChange: Object,
  },
  created() {
    this.account0 = process.env.VUE_APP_ADMIN0;
    this.account1 = process.env.VUE_APP_ADMIN1;
    this.account2 = process.env.VUE_APP_ADMIN3;
  },
  data() {
    return {
      account0: null,
      account1: null,
      account2: null,
    };
  },
};
</script>
