<template>
  <div class="my-2 mx-1 buttonSide">
    <b-button
      @click="$emit('goStake')"
      class="my-2 buttonSplit smallIconColor align-items-center"
    >
      <img src="../../assets/img/group_add_opsz20.svg" class="marginRight2" />
      &nbsp;
      <div class="centerw">{{ text1 }}</div>
    </b-button>
  </div>
</template>

<script>
export default {
  name: "SmallMove",
  emits: ["goStake"],
  props: {
    text1: String,
  },
};
</script>
