<template>
  <div class="positionRelative">
    <div
      class="evenlyPlz pointer mb-4"
      @click="$emit('openInNewTabCall', link)"
    >
      <div class="d-flex align-items-center">
        <img src="../../assets/img/campaign_black_18dp.svg" alt="" />
        <strong>
          {{ notionCut }}
        </strong>
        <strong>
          {{ notionCut3 > 18 ? "..." : "." }}
        </strong>
      </div>
      <div>
        <strong> {{ notionCut2 }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notion",
  emits: ["openInNewTabCall"],
  props: {
    notionCut: String,
    notionCut2: String,
    notionCut3: Number,
    link: String,
  },
};
</script>
