import Web3 from "web3";

const web3 = new Web3(window.ethereum);

export const fromWei = (amount) => {
  const change18 = web3.utils.fromWei(amount, "ether");
  return change18;
};
export const toWei = (amount) => {
  // const web3 = new Web3(window.ethereum)
  const change0 = web3.utils.toWei(amount, "ether");
  return change0;
};

export const BN = (amount) => {
  const BN = web3.utils.BN;
  const changeBN = new BN(amount);
  return changeBN;
};
