import { callWeb3 } from "./getWeb3";
import membershipABI from "../assets/data/membershipABI.json";
import tokenABI from "../assets/data/tokenABI.json";

//바이넨스
export const tokenAddress = process.env.VUE_APP_TOKEN;
export const membership = process.env.VUE_APP_MEMBERSHIP;
const networkId = process.env.VUE_APP_NETWORK;

export const getContract = async () => {
  const web3 = await callWeb3();

  const network1 = await web3.eth.getChainId();
  if (network1 == networkId) {
    const tokenContract = await new web3.eth.Contract(tokenABI, tokenAddress);

    const membershipContract = await new web3.eth.Contract(
      membershipABI,
      membership
    );
    const twoCN = [tokenContract, network1, membershipContract];
    return twoCN;
  } else {
    return false;
  }

  // console.log(myContract)
};

export const getTokenContract = async () => {
  const web3 = await callWeb3();

  const network1 = await web3.eth.getChainId();
  if (network1 == networkId) {
    const tokenContract = await new web3.eth.Contract(tokenABI, tokenAddress);

    return tokenContract;
  } else {
    return false;
  }
};

export const getMembershipContract = async () => {
  const web3 = await callWeb3();

  const network1 = await web3.eth.getChainId();
  if (network1 == networkId) {
    const membershipContract = await new web3.eth.Contract(
      membershipABI,
      membership
    );

    return membershipContract;
  } else {
    return false;
  }
};
