<template>
  <div class="px-0">
    <!-- <br /> -->
    <div class="row justify-content-center m-0">
      <div class="px-0">
        <div class="allFontSize">
          <!-- 메인 화면 시작 -->
          <div class="container-lg">
            <!-- 트랜잭션 중 오버래이 되는 화면 -->
            <b-overlay :show="showWait" rounded="sm">
              <!-- 메인 화면 카드 -->
              <div :aria-hidden="showWait ? 'true' : null">
                <!-- 메인 화면 상단 -->
                <div class="headerFix">
                  <!-- 화면 타이틀 및 메인화면 되돌아가기 -->
                  <div v-if="windowWidth >= 1024">
                    <MainHeaderBig
                      :text1="truncatedUserAddress"
                      @goMain="goMain"
                      @languageChangEn="languageChangEn"
                      @languageChangKr="languageChangKr"
                    />
                  </div>
                  <div v-else>
                    <MainHeaderSmall v-if="nextPage === 0" />
                    <!-- 사이드바 시작 -->
                    <Sidebar
                      :text1="truncatedUserAddress"
                      :text2="setLanguage.newSide9"
                      :text3="setLanguage.newSide8"
                      :text6="setLanguage.newSide5"
                      :areYou="areYou"
                      @languageChangEn="languageChangEn"
                      @languageChangKr="languageChangKr"
                      @addTokenCall="addTokenCall"
                      @sideToAdminCall="sideToAdminCall"
                    />
                    <!-- 사이드바 끝 -->
                    <MobileGoHomeIcon
                      v-show="nextPage === 1"
                      :text="setLanguage.newSide3"
                      @click.native="goMain"
                    />

                    <MobileGoHomeIcon
                      v-show="nextPage === 3"
                      :text="setLanguage.newSide5"
                      @click.native="goMain"
                    />
                  </div>
                  <!-- 화면 타이틀 및 메인화면 되돌아가기 -->
                </div>
                <!-- 메인 화면 상단 끝 -->

                <div class="sideFixed" v-if="windowWidth >= 1024">
                  <MainLeftLeftSide
                    :text1="setLanguage.newSide10"
                    :text2="setLanguage.newSide3"
                    :text4="setLanguage.newSide5"
                    :text5="setLanguage.newSide1"
                    :text6="setLanguage.newSide2"
                    :areYou="areYou"
                    :colorChange="colorChange"
                    @goMain="goMain"
                    @goStake="goStake"
                    @sideToAdminCall="sideToAdminCall"
                    @addTokenCall="addTokenCall"
                  />
                </div>

                <!-- 메인 화면 -->
                <b-card
                  pills
                  border-variant="white"
                  class="paddingLeft possitionRight"
                >
                  <!-- 카드하고 QR코드 시작 -->

                  <div v-show="nextPage !== 3">
                    <MainCardFront
                      :gradeToImage="gradeToImage"
                      :bannerList="bannerList"
                      :key="cardKey"
                    />
                    <!-- textCarousel -->
                    <div v-show="nextPage === 0">
                      <MainFrstNotion
                        :notionCut="notionCut"
                        :notionCut2="notionCut2"
                        :notionCut3="notionCut3"
                        :link="textCarousel.link"
                        @openInNewTabCall="openInNewTabCall"
                      />
                    </div>
                  </div>
                  <!-- 카드하고 QR코드 끝 -->
                  <div v-show="nextPage === 0">
                    <div v-if="windowWidth < 1024">
                      <!-- 넓이 1024 이하일 때 화면 이동 버튼 -->
                      <MainFrstSmallMove
                        :text1="setLanguage.newSide3"
                        @goStake="goStake"
                      />
                      <!-- 넓이 1024 이상일 때 화면 이동 버튼 끝-->
                    </div>
                    <!-- 메인화면의 디테일한 정보 -->

                    <div v-if="waitingSeeData === false">
                      <div class="text-center my-5">
                        <b-spinner></b-spinner>
                      </div>
                    </div>
                    <div v-else>
                      <div class="detail m-0 row">
                        <div
                          class="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-xs-12"
                        >
                          <h4 class="detailTop mx-2 d-flex position-relative">
                            <strong> {{ setLanguage.newSecond1 }}</strong>
                          </h4>
                          <div class="mx-1 forTable">
                            <b-table
                              stacked
                              borderless
                              :items="myDetail"
                              class="tableRight fontPopins fontTable"
                            >
                            </b-table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 메인화면의 디테일한 정보 끝 -->

                  <div class="detail m-0 row">
                    <div
                      class="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-xs-12 p-0"
                    >
                      <div v-show="nextPage === 1">
                        <div>
                          <!-- 가입하고자 하는 멤버십 선택 -->
                          <b-form-radio-group
                            v-model="grade"
                            :options="options"
                            :disabled="
                              this.contractAllowance < 50000000000000000000000
                            "
                            class="d-flex justify-content-between radioL flex-wrap mb-3"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                          ></b-form-radio-group>

                          <!-- 가입하고자 하는 멤버십 선택 끝 -->

                          <div class="d-flex justify-content-between">
                            <!-- 원하는 멤버십에 가입하려면 지불해야하는 토큰의 양 -->
                            <MainStakeTokenAmount
                              :grade="grade"
                              :seeJoinMembership="seeJoinMembership"
                              :text1="setLanguage.newThird1"
                            />
                            <!-- 원하는 멤버십에 가입하려면 지불해야하는 토큰의 양 끝 -->

                            <!-- 승인 및 멤버쉽 업그레이드 버튼 -->
                            <div
                              class="buttonCenter buttonRadius1 buttonFontSize"
                            >
                              <!-- 이미 가입 한사람 -->
                              <BasicButtonLongG
                                v-if="nowGrade != 'None'"
                                class="sizee7 buttonFontSize notAllow"
                                :text="setLanguage.newThird14"
                              />
                              <!-- 이미 가입 한사람 끝 -->
                              <!-- 어프로브 -->
                              <BasicButtonLong
                                v-else-if="
                                  this.contractAllowance <
                                  50000000000000000000000
                                "
                                @click.native="
                                  showMsgBoxOne(setLanguage.help7, 0)
                                "
                                class="sizee7 buttonFontSize"
                                :text="setLanguage.newThird3"
                              />
                              <!-- 어프로브 끝 -->
                              <!-- @click.native="approve(testMembership)" -->
                              <!-- 토큰부족  -->
                              <BasicButtonLongG
                                v-else-if="myTokenBalance < joinMembership"
                                class="sizee7 buttonFontSize notAllow"
                                :text="setLanguage.newThird12"
                              />
                              <!-- 토큰부족 끝-->
                              <!-- 가입 및 업그레이드 -->
                              <BasicButtonLong
                                v-else
                                @click.native="
                                  showMsgBoxOne(setLanguage.help8, 1)
                                "
                                class="sizee7 buttonFontSize"
                                :disabled="grade == null"
                                :text="setLanguage.newThird4"
                              />
                              <!-- 가입 및 업그레이드 -->
                            </div>
                            <!-- 승인 및 멤버쉽 업그레이드 버튼 끝 -->
                          </div>

                          <!-- 업그레이 관련 설명  -->
                          <p
                            class="mt-2 iconColor1 mb-4 smText"
                            v-if="nowGrade != 'None'"
                          >
                            {{ setLanguage.newThird13 }} - {{ nowGrade }}
                          </p>
                          <p
                            class="mt-2 iconColor1 mb-4 smText"
                            v-else-if="
                              this.contractAllowance < 50000000000000000000000
                            "
                          >
                            {{ setLanguage.newThird11 }}
                          </p>
                          <p v-else class="mt-2 iconColor1 mb-4 smText">
                            {{ setLanguage.newThird5 }}
                          </p>
                          <!-- 업그레이 관련 설명  끝-->

                          <!-- 멤버쉽 설명 -->
                          <b-table
                            :items="membershipHelp"
                            outlined
                            :small="this.windowWidth >= 1024 ? null : 'small'"
                            class="mt-2 mb-1 text-center sizee90 fontSizeSm"
                            id="membershipDetail"
                          >
                            <!-- <template #head(전환수수료)="data">
                              <span>{{ data.label }}</span>
                              <QuestionMark
                                @click.native="
                                  showMsgBoxTwo(
                                    `'EDT'와 '포인트' 간의 전환에 필요한 수수료`
                                  )
                                "
                              />
                            </template> -->
                            <template #head(혜택)="data">
                              <span>{{ data.label }}</span>
                              <QuestionMark
                                @click.native="
                                  showMsgBoxTwo(
                                    `EDUSTAR에서 제공하는 게임에 참여 할 수 있는 횟수 입니다.`
                                  )
                                "
                              />
                            </template>
                            <!-- <template #head(Commission)="data">
                              <span>{{ data.label }}</span>
                              <QuestionMark
                                @click.native="
                                  showMsgBoxTwo(
                                    `Fee required for conversion between 'EDT' and 'Points'`
                                  )
                                "
                              />
                            </template> -->
                            <template #head(Benefits)="data">
                              <span>{{ data.label }}</span>
                              <QuestionMark
                                @click.native="
                                  showMsgBoxTwo(
                                    `The number of times you can participate in the game provided by EDUSTAR.`
                                  )
                                "
                              />
                            </template>
                          </b-table>
                        </div>
                      </div>

                      <!-- 관리자 페이지 -->
                      <div v-if="nextPage === 3">
                        <AdminTvl :TVL="TVL" :balance="balance" />
                        <hr />
                        <!-- 사용자 등급별 조회 -->
                        <!-- 등급 누르면 해당 등급자들 좌르륵 -->
                        <div class="mt-5 mb-4">
                          <div class="mb-3">
                            <span class="fs-6"
                              ><strong>{{ setLanguage.fourth4 }}</strong></span
                            >
                          </div>
                          <div
                            class="d-flex justify-content-between buttonRadius1"
                          >
                            <b-form-select
                              v-model="grade1"
                              class="adminSelectBar borderRadius"
                              :options="options2"
                            ></b-form-select>
                            <!-- 등급 분류가 아직 끝나지 않았을 때 -->
                            <BasicButton
                              v-if="this.addressToDateTF == false"
                              disabled
                              text="wait..."
                            />
                            <!-- 등급 분류가 아직 끝나지 않았을 때 끝 -->
                            <!-- 등급 분류가 끝났을 때 -->
                            <BasicButton
                              v-else
                              @click.native="seeGrade()"
                              :text="setLanguage.fourth5"
                            />
                          </div>
                          <!-- 등급 분류가 끝났을 때 끝 -->
                          <!-- 조회 결과 없음 -->

                          <!-- v-else -->
                          <!-- 조회 결과  -->
                          <b-table
                            id="my-table"
                            class="mt-3 text-center smText"
                            :items="user"
                            :per-page="10"
                            :current-page="currentPage"
                            table-variant="white"
                            responsive="true"
                          ></b-table>
                          <!-- 조회 결과 끝 -->
                        </div>
                        <b-pagination
                          class="mb-5"
                          v-model="currentPage"
                          pills
                          :total-rows="rows"
                          :per-page="10"
                          aria-controls="my-table"
                        >
                          <template #page="{ page, active }">
                            <b v-if="active">{{ page }}</b>
                            <i v-else class="adminPageElse">{{ page }}</i>
                          </template>
                        </b-pagination>
                        <!-- 사용자 등급별 조회 끝 -->

                        <hr />
                        <!-- 사용자 조회 -->
                        <div class="my-5">
                          <div
                            class="d-flex justify-content-between buttonRadius1"
                          >
                            <span class="fs-6"
                              ><strong>{{
                                setLanguage.newFourth2
                              }}</strong></span
                            >

                            <BasicButton
                              @click.native="addressToGrade()"
                              :text="setLanguage.fourth5"
                            />
                          </div>

                          <input
                            type="text"
                            v-model="customerAddress"
                            :placeholder="setLanguage.fourth8"
                            class="sizee rowBorder borderRadius px-2 mt-3"
                          />
                          <b-spinner v-if="addressToGradeWait"></b-spinner>
                          <b-card-text v-else class="mt-3">{{
                            userGrade
                          }}</b-card-text>
                        </div>
                        <!-- 사용자 조회 끝 -->
                        <hr class="mt-5" />

                        <!-- 공지사항 등록 -->
                        <AdminNotionUp
                          :notification="notification"
                          :notificationNumList="notificationNumList"
                          :account="account"
                          :text1="setLanguage.newFourth3"
                          :text2="setLanguage.newFourth4"
                          :text3="setLanguage.newFourth5"
                          :text4="setLanguage.newFourth6"
                          :text5="setLanguage.newFourth7"
                          :text6="setLanguage.newFourth8"
                          :text7="setLanguage.newFourth9"
                          :text8="setLanguage.newFourth10"
                          :text9="setLanguage.newFourth11"
                          :text10="setLanguage.newFourth12"
                          :text11="setLanguage.newFourth13"
                          :text12="setLanguage.newFourth14"
                          @notificationCopy="notificationCopy"
                          @numListCopy="numListCopy"
                          @numListCut="numListCut"
                          @numListCut1="numListCut1"
                          @notificationCut="notificationCut"
                          @notificationUp="notificationUp"
                          @carouselUp="carouselUp"
                          @msgBox="msgBox"
                        />
                        <!-- 공지사항 등록 끝 -->
                        <hr />
                        <!-- 배너 조회 -->
                        <div class="mt-5">
                          <span class="fs-6"
                            ><strong>{{
                              setLanguage.newFourth21
                            }}</strong></span
                          >

                          <div class="mt-3 fontSizeSmB iconColor1">
                            {{ setLanguage.mef5 }}
                          </div>
                        </div>

                        <AdminBanner
                          :bannerList="bannerList"
                          :account="account"
                          :text1="setLanguage.help12"
                          :text2="setLanguage.help13"
                          :text3="setLanguage.help14"
                          :text4="setLanguage.help15"
                          :text5="setLanguage.help16"
                          @bannerUpdate="bannerUpdate"
                        />
                        <!-- 배너 끝 -->
                      </div>
                    </div>
                  </div>
                </b-card>
                <!-- 메인 화면 끝 -->
              </div>
              <template #overlay>
                <MainOverlay @showWaitFalse="showWaitFalse" />
              </template>
            </b-overlay>
          </div>
        </div>
        <!-- col 끝 -->
      </div>
      <!-- row 끝 -->
    </div>
    <!-- 컨테이너 끝 -->
  </div>
</template>
<script>
import {
  getContract,
  getTokenContract,
  getMembershipContract,
  membership,
  tokenAddress,
} from "../control/contractData";
import { fromWei, toWei, BN } from "../control/fromWei";
import "../style/Index.css";
import lenguageData from "../assets/data/language.json";
import networks from "../assets/data/networks.json";
import modalData from "../assets/data/modal.json";
import BasicButton from "../components/basicUse/BasicButton.vue";
import BasicButtonLong from "../components/basicUse/BasicButtonLong.vue";
import BasicButtonLongG from "../components/basicUse/BasicButtonLongG.vue";

import MobileGoHomeIcon from "../components/basicUse/MobileGoHomeIcon.vue";
import MainHeaderBig from "../components/mainHeader/Big.vue";
import MainHeaderSmall from "../components/mainHeader/Small.vue";
import Sidebar from "../components/mainHeader/Sidebar.vue";
import MainLeftLeftSide from "../components/mainLeft/LeftSide.vue";
import MainCardFront from "../components/mainCard/Front.vue";
import MainFrstNotion from "../components/mainFirst/Notion.vue";
import MainFrstSmallMove from "../components/mainFirst/SmallMove.vue";
import MainStakeTokenAmount from "../components/mainStake/TokenAmount.vue";
import AdminNotionUp from "../components/admin/NotionUp.vue";
import AdminBanner from "../components/admin/Banner.vue";
import AdminTvl from "../components/admin/Tvl.vue";
import MainOverlay from "../components/Overlay.vue";
import QuestionMark from "../components/Question.vue";
import { tvlChange } from "../control/tvlChange";
import { callWeb3 } from "../control/getWeb3";
import { addNetwork, addToken } from "../control/metamask1";
import { colorChangeFunction } from "../control/colorChange";
import {
  lang,
  getLanguage,
  openInNewTab,
  clearRefresh,
} from "../control/basicSet";
import { axiosCall, callBasic } from "../control/server";
import { switchGrade, gradeToText } from "../control/grade";
import { updateTable, updateDetail } from "../control/update";
import {
  getUserInfo,
  getOwner,
  getDepositData,
  seeAddressToGrade,
  getTVL,
} from "../control/contractMembership";
import {
  myBalance,
  //mySymbol,
  allowance,
} from "../control/contractToken";
import { UserAddress } from "../control/userAddress";
import { check } from "../control/admin";
export default {
  name: "",
  components: {
    BasicButton,
    BasicButtonLong,
    BasicButtonLongG,
    MobileGoHomeIcon,
    MainHeaderBig,
    MainHeaderSmall,
    Sidebar,
    MainLeftLeftSide,
    MainCardFront,
    MainFrstNotion,
    MainFrstSmallMove,
    MainOverlay,
    MainStakeTokenAmount,
    AdminTvl,
    AdminNotionUp,
    AdminBanner,
    QuestionMark,
  },
  data() {
    return {
      callWeb3,
      fromWei,
      toWei,
      BN,
      addNetwork,
      addToken,
      colorChangeFunction,
      lang,
      getLanguage,
      openInNewTab,
      clearRefresh,
      axiosCall,
      callBasic,
      switchGrade,
      updateTable,
      updateDetail,
      getUserInfo,
      myBalance,
      // mySymbol,
      allowance,
      UserAddress,
      getOwner,
      getDepositData,
      seeAddressToGrade,
      tvlChange,
      modalData,
      check,
      gradeToText,
      getTVL,
      lenguageData,
      networks,
      //test2ABI,
      pageSwitch: false,
      showSelect: false,
      membership,
      tokenAddress,
      tabIndex: 0,
      account: null,
      balance: null,
      getContract,
      getTokenContract,
      getMembershipContract,
      contractAllowance: null,
      depositFee: null,

      customer: null,
      grade: null,
      nowGrade: null,
      options: [
        { item: "4", name: "Red Diamond", notEnabled: false },
        { item: "3", name: "Blue Diamond", notEnabled: false },
        { item: "2", name: "Black Diamond", notEnabled: false },
        { item: "1", name: "Diamond", notEnabled: false },
      ],
      grade1: null,
      resultGrade: [],
      options2: [
        { value: null, text: "-- Please select a grade --" },
        { value: "4", text: "Red Diamond" },
        { value: "3", text: "Blue Diamond" },
        { value: "2", text: "Black Diamond" },
        { value: "1", text: "Diamond" },
        // { value: "5", text: "Bronze" }, 기록한적이 없어서 검색 불가
        { value: "999", text: "Green Diamond" },
      ],
      customerAddress: null,
      userAddress: null,
      // 멤버변수 시작
      // symbol: null,
      userGrade: null,
      //qr

      joinMembership: null,
      seeJoinMembership: null,
      //web3: null,

      network: 0,
      truncatedUserAddress: null,
      setLanguage: null,
      rotateSearch: 0,
      showWait: false,
      testtest: null,

      user: [],
      addressToDateTF: false,
      imageChange: false,

      myDetail: [],
      myDetail1: [],

      nextPage: 0,
      membershipHelp: [],
      membershipApy: [3, 5, 7, 10, 1],
      windowWidth: window.innerWidth,
      gradeToImage: "card0.png",
      colorChange: {
        stake: "iconColor1",
        unstake: "iconColor1",
        admin: "iconColor1",
        realStake: "iconColor1",
      },
      depositFee50: [],
      notification: [
        {
          main: "안녕하세요 테스트 공지입니다 클릭시 네이버로 이동",
          date: "2002-06-03",
          link: "http://www.naver.com",
          num: 0,
        },
        {
          main: "클릭시 EDUSATR로 이동합니다",
          date: "2022-06-03",
          link: "https://www.edustar.io/ko",
          num: 1,
        },
      ],
      textCarousel: {
        main: "안녕하세요 테스트 공지입니다.",
        date: "06-03",
      },
      textChangeNum: 0,
      notificationDate: null,
      notificationLink: null,
      notificationTextUpdate: null,
      notificationDateUpdate: null,
      notificationLinkUpdate: null,
      notificationNumList: [2, 3, 4, 5, 6, 7, 8, 9],
      seletedNotion: null,
      showNotion: false,
      lookNum: null,
      waitingSeeData: false,
      oneTwo: 0,
      USD: null,
      showEarn: null,
      dollarEarn: null,
      stakingDataCall: false,
      stakingModal: false,
      expectedAmount: 0,
      badgeSetting: false,
      stakingModalData: {
        name: null,
        amount: null,
        functionName: null,
      },
      contracts: [],
      stakeButtonGroup: "buttonOutsetColor",
      stakeButtonGroup1: "buttonOutsetColor",
      stakeButtonGroup2: "buttonOutsetColor",
      stakingRadio: 0,

      currentPage: 1,
      refresh: null,
      noti: null,
      TVL: null,
      addressToGradeWait: false,
      myTokenBalance: null,
      contractOwner: null,
      bannerList: [],
      cardKey: 0,
      areYou: false,
      membershipCount: 5,
    };
  },
  computed: {
    notionCut() {
      return this.textCarousel.main.slice(0, 18);
    },
    notionCut2() {
      return this.textCarousel.date.slice(5, 10);
    },
    notionCut3() {
      return this.textCarousel.main.length;
    },
    rows() {
      return this.user.length;
    },
  },
  watch: {
    // 내 멤버쉽 등급에 따라, 원하는 등급이 되고 싶을 때, 스테이킹 해야 할 가격 계산
    async grade(val) {
      if (!val) return;
      this.switchFee(this.grade);
      //지수표현식으로 나와서 빅넘버 사용
      this.calculateJoin();
    },
  },
  setup() {},
  async created() {
    this.setLanguage = this.lang();

    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      this.account = accounts[0];
    } catch (error) {
      this.metamaskInstall();
      // this.showMsgBoxTwo(a);
    }

    window.ethereum.on("accountsChanged", async () => {
      if (this.account != null) {
        window.location.reload();
      }
    });
    window.ethereum.on("chainChanged", () => {
      if (this.account != null) {
        window.location.reload();
      }
      window.location.reload();
    });

    let getCon = await this.getContract();

    if (getCon) {
      this.network = getCon[1];

      const result = await this.getDepositData();
      this.depositFee50 = result[0];
      this.USD = result[1];
      this.membershipCount = result[2];

      // this.test2 = await this.realCheck1(this.test1);
      const checkAddress = await this.check();

      this.areYou = checkAddress;
      if (checkAddress) {
        this.resultGrade = await this.seeAddressToGrade(this.membershipCount);
        this.addressToDateTF = true;
      }

      this.init();
      this.contractOwner = await this.getOwner();
      // console.log(this.contractOwner);
      // console.log(this.account);
    } else {
      alert(this.setLanguage.mef6);
      // this.addNetwork(this.networks.Binance);
      this.addNetwork(this.networks.Binance);
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.onResize();
    this.textChange();
  },
  unmounted() {},
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  destroyed() {
    clearInterval(this.noti);
  },
  methods: {
    // 컴포넌트 에밋----------------------------------------------
    // 첫화면
    showSelectChange(msg) {
      this.showSelect = msg;
    },
    pageSwitchChange(msg) {
      sessionStorage.setItem("pageSwitch", msg);
      this.pageSwitch = msg;
    },
    accountChange(msg) {
      this.account = msg;
    },
    metamaskInstall() {
      const h = this.$createElement;
      const hashShort = this.setLanguage.first10;
      // h('div', ['hello', h('a')])
      const msg = h("div", [
        this.setLanguage.first9,
        h("a", {
          domProps: {
            innerHTML: hashShort,
            href: process.env.VUE_APP_METAMASKINSTALL,
            target: "_blank",
          },
        }),
      ]);
      this.showMsgBoxTwo(msg);
    },

    // 첫화면 끝
    // 메인 헤더
    async goMain() {
      this.nextPage = 0;
      sessionStorage.setItem("nextPage", 0);
      this.updateSmall();
      this.colorChange = this.colorChangeFunction(3);
    },
    languageChangEn() {
      this.setLanguage = lenguageData.en;
      this.updateSmall();
      this.membershipHelp = this.updateTable(this.setLanguage);
    },
    languageChangKr() {
      this.setLanguage = lenguageData.kr;
      this.updateSmall();
      this.membershipHelp = this.updateTable(this.setLanguage);
    },
    addTokenCall() {
      this.addToken();
    },
    async sideToAdminCall() {
      this.nextPage = 3;
      sessionStorage.setItem("nextPage", 3);
      this.colorChange = this.colorChangeFunction(2);
      this.resultGrade = await this.seeAddressToGrade(this.membershipCount);
      // this.readyForInterest = this.resultGrade[6];
      this.addressToDateTF = true;
    },
    // 메인 헤더 끝
    // 메인 왼쪽
    goStake() {
      this.nextPage = 1;
      sessionStorage.setItem("nextPage", 1);
      this.colorChange = this.colorChangeFunction(0);
    },
    // 메인 왼쪽 끝

    // 메인 첫화면
    openInNewTabCall(msg) {
      this.openInNewTab(msg);
    },
    // 메인 첫화면 끝
    // 오버레이
    showWaitFalse() {
      this.showWait = false;
    },
    // 오버레이 끝

    notificationCopy(msg) {
      this.notification = msg;
    },
    numListCopy(msg) {
      this.notificationNumList = msg;
    },
    numListCut() {
      this.notificationNumList.splice(0, 1);
    },
    numListCut1(msg) {
      this.notificationNumList.unshift(msg);
    },
    notificationCut(msg) {
      this.notification.splice(msg, 1);
    },
    notificationUp(msg) {
      this.notification.push(msg);
    },
    msgBox(msg) {
      this.showMsgBoxTwo(msg);
    },
    carouselUp() {
      this.textCarousel = {
        main: this.setLanguage.newFourth15,
        date: "00-00",
        link: "https://www.edustar.io/ko",
      };
    },
    async bannerUpdate() {
      this.bannerList = await this.callBasic();
      this.cardKey += 1;
    },
    // 어드민 끝
    // 컴포넌트 에밋 끝-----------------------------------------------

    // 화면 넓이
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    // 새로고침 하고 페이지 이동
    getNextPage() {
      switch (sessionStorage.getItem("nextPage")) {
        case "0":
          this.goMain();
          break;
        case "1":
          this.goStake();
          break;
        case "3":
          this.sideToAdminCall();
          break;
      }
    },

    // 등급별 가격 확인
    async switchFee(grade) {
      const numGrade = Number(grade);
      this.depositFee = this.depositFee50[numGrade];
    },

    // 지불해야하는 금액 계산
    calculateJoin() {
      let a = this.BN(this.depositFee);
      // let b = this.BN(this.addressToDeposit);
      // let money = a.sub(b).toString();
      // 계산 결과가 음수인지 확인
      // if (money.slice(0, 1) == "-") {
      //   money = "0";
      // }

      let money = a.toString();
      this.seeJoinMembership = this.fromWei(money);
      this.joinMembership = money;
    },

    // --- 이벤트 ---
    // 공지 내용 돌아가며 변경
    textChange() {
      this.textCarousel = this.notification[this.textChangeNum];

      clearInterval(this.noti);
      this.noti = setInterval(() => {
        if (this.textChangeNum < this.notification.length) {
          this.textCarousel = this.notification[this.textChangeNum];
          this.textChangeNum++;
        } else {
          this.textChangeNum = 0;
        }
      }, 4000);
    },

    // --- 이벤트 끝 ---

    // 처음 시작시
    async init() {
      this.contractAllowance = await this.allowance(
        this.membership,
        this.account
      );
      this.waitingSeeData = false;
      this.colorChange = this.colorChangeFunction(3);
      this.truncatedUserAddress = this.UserAddress(this.account);
      this.bannerUpdate();
      this.membershipHelp = this.updateTable(this.setLanguage);

      const userInfo = await this.getUserInfo(this.account);
      this.switchGradeToText(userInfo);
      // 여기 뭐지 왜 nowGrade 지?
      // this.switchFee(this.nowGrade);
      this.radioControl(this.nowGrade);
      this.dollar = 1 / this.USD;
      this.updateSmall();
      this.waitingSeeData = true;

      const mefBalance = await this.getTVL();
      const balanceDollar = mefBalance * this.dollar;
      this.TVL = balanceDollar.toFixed(0);

      const myTokenAmount = await this.myBalance(this.account);
      this.myTokenBalance = Number(toWei(myTokenAmount));
      const tvl1 = balanceDollar.toFixed(0);
      this.balance = this.tvlChange(mefBalance);
      this.TVL = this.tvlChange(tvl1);

      const ser = await this.axiosCall();
      this.notification = ser.c;
      this.notificationNumList = ser.updateA;

      this.pageSwitch = sessionStorage.getItem("pageSwitch");
      this.getNextPage();
    },

    // 테이블 안의 내용
    updateSmall() {
      const result = this.updateDetail(
        this.nowGrade,

        this.setLanguage.newSecond7
      );
      this.myDetail = result;
    },

    //라디오 버튼 컨트롤
    radioControl(nowGrade) {
      if (nowGrade === "None") {
        this.options[3].notEnabled = false;
        this.options[2].notEnabled = false;
        this.options[1].notEnabled = false;
        this.options[0].notEnabled = false;
      } else {
        this.options[3].notEnabled = true;
        this.options[2].notEnabled = true;
        this.options[1].notEnabled = true;
        this.options[0].notEnabled = true;
      }
    },

    //컨트랙트에 내 등급에 따른 등급 이름
    switchGradeToText(grade) {
      const result = this.gradeToText(grade);
      this.nowGrade = result[0];
      this.gradeToImage = result[1];
      this.cardKey += 1;
    },

    // 해당 등급에 스테이깅 중인 유저 보여주기위해
    seeGrade() {
      const grade = Number(this.grade1);
      this.user = this.resultGrade[grade];
    },

    //계정 주소로 등급 확인
    async addressToGrade() {
      try {
        this.addressToGradeWait = true;
        const userGrade = await this.getUserInfo(this.customerAddress);
        this.userGrade = this.switchGrade(userGrade);
        this.addressToGradeWait = false;
      } catch {
        this.showMsgBoxTwo(this.setLanguage.scriptMeg8);
        this.addressToGradeWait = false;
      }
    },

    //--------------------- 트랜잭션 발생 -------------------------

    //토큰 사용 승인 요청
    async approve(spender) {
      this.showWait = true;
      const contract = await this.getTokenContract();
      await contract.methods
        .increaseAllowance(spender, "30000000000000000000000000")
        .send({
          from: this.account,
        })
        .on("error", (err) => {
          console.log(err);
          this.showWait = false;
        });
      this.showWait = false;
      this.contractAllowance = await this.allowance(spender, this.account);
    },

    //스테이킹
    async beforeDeposit() {
      this.showWait = true;
      let transactionHash;
      const stringJoin = this.joinMembership;
      const contract = await this.getMembershipContract();

      await contract.methods
        .deposit(this.grade, stringJoin)
        .send({
          from: this.account,
        })
        .on("transactionHash", (hash) => {
          transactionHash = hash;
        })
        .on("error", (err) => {
          console.log(err);
          this.showMsgBoxTwo(this.setLanguage.help24);
          this.showWait = false;
        });

      this.deposit(transactionHash);
    },

    async deposit(transactionHash) {
      this.contractAllowance = await this.allowance(
        this.membership,
        this.account
      );

      const userInfo = await this.getUserInfo(this.account);

      this.switchGradeToText(userInfo);
      this.hashScan(
        transactionHash,
        this.setLanguage.help22 + this.nowGrade + this.setLanguage.help23
      );

      this.calculateJoin();
      this.radioControl(this.nowGrade);

      this.updateSmall();
      this.grade = null;

      const myTokenAmount = await this.myBalance(this.account);
      this.myTokenBalance = Number(toWei(myTokenAmount));

      this.showWait = false;
    },
    //--------------------- 트랜잭션 발생 -------------------------

    // bsc 스캔 a태그 만들기
    hashScan(transactionHash, msg) {
      // createElement 를 못찾아서 따로 안빼둠
      // createElement: vue 내장 render 함수
      const h = this.$createElement;
      const hashShort =
        transactionHash.slice(0, 15) + " ... " + transactionHash.slice(53, 66);

      const txhash = h("div", [
        h("div", msg),
        // h("br"),
        h("span", this.setLanguage.help19),
        h("a", {
          domProps: {
            innerHTML: hashShort,
            href: `https://bscscan.com/tx/${transactionHash}`,
            target: "_blank",
          },
        }),
      ]);
      this.showMsgBoxTwo(txhash);
    },

    // 모달창 ------------------------------------------------
    // this$bvModal을 못찾아서 여기둠
    showMsgBoxTwo(msg) {
      let modal = this.modalData;
      modal.okTitle = this.setLanguage.mef7;
      this.$bvModal.msgBoxOk(msg, modal);
    },

    showMsgBoxOne(msg, num) {
      let modal = this.modalData;
      modal.okTitle = this.setLanguage.mef7;
      modal.cancelTitle = this.setLanguage.mef8;
      modal.cancelVariant = "danger";
      this.$bvModal.msgBoxConfirm(msg, this.modalData).then((value) => {
        if (value) {
          switch (num) {
            case 0:
              this.approve(this.membership);
              break;
            case 1:
              this.beforeDeposit();
              break;
          }
        }
      });
    },
  },
};
</script>
