<template>
  <div class="mt-4 mb-5">
    <div>
      <input
        type="file"
        class="form-control"
        accept="image/png,image/jpeg"
        @change="uploadFile($event.target.files)"
      />
    </div>
    <b-row>
      <b-col v-for="(m, n) in bannerList" :key="n">
        <div class="position-relative">
          <div class="text-end pointer" @click="deleteImage(m)">X</div>

          <b-img thumbnail :src="`${call}/api/download/${m}`" alt="banner" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { api } from "../../control/axios";
import { check } from "../../control/admin";
export default {
  name: "Banner",
  emits: ["bannerUpdate"],
  props: {
    bannerList: Array,
    text1: String,
    text2: String,
    text3: String,
    text4: String,
    text5: String,
    account: String,
  },
  created() {
    this.call = process.env.VUE_APP_BASEURL;
  },
  data() {
    return {
      api,
      check,
      list3: [],
      admin: null,
    };
  },
  methods: {
    //t_basic 에 이미지 업로드
    async uploadFile(files) {
      let name = "";
      let data = null;
      if (files) {
        name = files[0].name;
        data = await this.base64(files[0]);
      }

      const checkAddress = await this.check();

      if (files[0].size > 1000000) {
        alert(this.text1);
      } else if (checkAddress) {
        const { error } = await this.api("/api/upload", "post", {
          param: [name, data, this.account],
        });
        if (error) {
          console.log(error);
          return alert(this.text2);
        }
        alert(this.text3);
        setTimeout(() => {
          this.$emit("bannerUpdate");
        }, 1000);
      } else {
        alert("admin only");
      }
    },

    async deleteImage(id) {
      const date = this.list3.indexOf(id);
      const checkAddress = await this.check();
      if (checkAddress) {
        const { error } = await this.api("/api/imageDelete", "post", {
          param: [id, this.account],
          date,
        });

        if (error) {
          // 없어도 되나?
          this.$emit("bannerUpdate");
          return alert(this.text4);
        }

        alert(this.text5);
        this.$emit("bannerUpdate");
      } else {
        alert("admin only");
      }
    },

    base64(file) {
      return new Promise((resolve) => {
        var a = new FileReader();
        a.onload = (e) => resolve(e.target.result);
        a.readAsDataURL(file);
      });
    },
  },
};
</script>
