<template>
  <div class="d-flex justify-content-between">
    <div class="pointer" @click="$emit('goMain')">
      <div class="d-flex align-items-center">
        <img src="../../assets/img/EDT_LOGO_S.png" alt="" />
        <span class="dustar mx-2">
          <strong>EDUSTAR </strong>
        </span>
      </div>
    </div>

    <div class="buttonRadius">
      <b-button size="sm" class="buttonAmpty buttonBorder1 iconColor1 font17">{{
        text1
      }}</b-button>
      <b class="iconColor1">|</b>

      <b-button
        size="sm"
        class="buttonAmpty buttonBorder1 iconColor1 font17"
        @click="$emit('languageChangEn')"
      >
        English
      </b-button>
      <b class="iconColor1">|</b>
      <b-button
        size="sm"
        class="buttonAmpty buttonBorder1 iconColor1 font17"
        @click="$emit('languageChangKr')"
      >
        한국어
      </b-button>
      <b class="iconColor1">|</b>
      <b-button
        size="sm"
        class="buttonAmpty buttonBorder1 topP"
        @click="logOut()"
      >
        <img src="../../assets/img/logout_black_24dp.svg" alt="" />
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Big",
  emits: ["goMain", "languageChangEn", "languageChangKr"],
  props: {
    windowWidth: Number,
    text1: String,
  },
  methods: {
    logOut() {
      sessionStorage.clear();
      window.open("https://edustar.site/", "_self");
    },
  },
};
</script>
