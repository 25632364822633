<template>
  <div class="d-flex sizee90 justify-content-center me-2">
    <b-row class="rowBorder borderRadius smText1 sizee90">
      <b-col cols="9" v-if="grade != 0">{{ seeJoinMembership }}</b-col>
      <b-col cols="9" v-else class="iconColor1"
        >{{ text1 }}
        <!-- ({{ waiting }}
                              {{ setLanguage.newThird0 }}) -->
      </b-col>
      <b-col cols="3" class="tableRight"><strong> EDT</strong></b-col>
    </b-row>
    <slot name="hello1234"></slot>
  </div>
</template>

<script>
export default {
  name: "TokenAmount",
  props: {
    grade: String,
    seeJoinMembership: String,
    text1: String,
  },
};
</script>
