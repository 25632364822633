<template>
  <b-button class="buttonColor" size="sm">{{ text }}</b-button>
</template>

<script>
export default {
  name: "BasicButton",
  props: ["text"],
};
</script>
